<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="all">
      <div v-for="(lesson,index) in kc_list" :key="index" @click="go_url(lesson)">
        <datalists :type="2" :list-type="3" :thumb="lesson.thumb" :atitle="lesson.lesson_name" :date="lesson.create_time" :havelearned="lesson.look_count" :allcourse="lesson.schedule_total"></datalists>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_kc_see } from '@/api/my_user.js'
import datalists from '@/compontens/datalist'
import Cookies from 'js-cookie'
import tool from '@/utils/tools'

export default {
  components: {
    heads,
    datalists
  },
  data() {
    return {
      title_msg: '浏览课程',
      isshow: false,
      kc_list: [],
      isZkb: false,
      agency_id: '',
      showMore: true,
      page: 1,
      limit: 50, // 我擦 竟然没分页，凑合50条吧
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: this.$route.query.zkb_pro || '',
      category_id: this.$route.query.category_id || '',
      category_name: decodeURIComponent(this.$route.query.category_name || '')
    }
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    if (this.zkb_pro == 1) {
      this.showMore = false
    }
    console.log('zkb_pro = ', this.zkb_pro)
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log(this.agency_id + this.isZkb + '-----------------')
  },
  mounted() {
    this.get_kc_list()
  },
  methods: {
    go_url(lesson) {
      if (this.isZkb) {
        // 注考帮
        const origin = window.location.origin
        // const lesson_url = `${origin}/edu/d/${lesson.lesson_id}.html?zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        const lesson_url = `${origin}/kb_timetable?lesson_id=${lesson.lesson_id}&zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        window.location.href = lesson_url
        // if (tool.isInApp()) {
        //   const str = 'index=0' + '&lesson_id=' + lesson.lesson_id + '&lesson_name=' + lesson.lesson_name
        //   if (tool.isInIosApp()) {
        //     window.webkit.messageHandlers.vue_openTabbar.postMessage(encodeURIComponent(str))
        //   } else {
        //     this.$jsbridge.callHandler(
        //       'vue_openTabbar'
        //       , str
        //       , function(responseData) {
        //         // android 返参数接受
        //       }
        //     )
        //   }
        // } else {
        //   // 注考帮h5
        //   const url = 'https://m.zk468.com/'
        //   Cookies.set('lesson_id', lesson.lesson_id, { expires: 365 })
        //   Cookies.set('lesson_name', lesson.lesson_name, { expires: 365 })
        //   window.location.href = url
        // }
      } else {
        var urls = lesson.url || ''
        var sliStr = urls.split('?')
        var url = sliStr[0] + '?wdsc=1&' + (sliStr[1] || '')
        window.location.href = url
      }
    },
    get_kc_list() {
      var params = {
        page: this.page,
        limit: this.limit,
        zkb_pro: this.zkb_pro
      }
      get_kc_see(params).then((res) => {
        this.kc_list = res.result.list
      })
    },
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.main {
  width: 100%;
  height: 203px;
  margin-bottom: 3px;
  background: #ffffff;
}
.main_left {
  width: 306px;
  height: 203px;
  float: left;
}
.gkjl_img {
  width: 250px;
  height: 155px;
  margin: 24px 0 0 29px;
}
.main_right {
  float: left;
  width: 406px;
  height: 170px;
  word-wrap: break-word;
  word-break: normal;
  position: relative;
}
.title {
  font-size: 30px;
  padding-top: 27px;
  color: #323136;
}
.gksj {
  color: #8c8b90;
  font-size: 25px;
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
